<template>
  <table-layout
  class="return-list-table"
  :items="mapItems"
  :fields="[]"
  :tileComponent="tableTileComponent" />
</template>

<script>
import dayjs from 'dayjs'
import TableLayout from 'theme/components/Tables/TableLayout'
import ListItemTile from 'theme/components/Tables/Tiles/ListItemTile'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tableTileComponent: ListItemTile
    }
  },
  computed: {
    mapItems () {
      return this.items.map(item => ({
        id: item.id,
        created_at: dayjs(item.created_at).format('DD/MM/YYYY'),
        status_id: item.status_id,
        status_label: item.status_label,
        status_color: `mst-rma-status status-branch-${item.status_label}`,
        order_id: item.order_id,
        order_increment: item.extension_attributes.increment_id
      }))
    }
  },
  components: {
    TableLayout
  }
}
</script>
<style lang="scss" scoped>
.rma-orders{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  li,
  article div,
  article{
    display: inline-block;
  }
}
</style>

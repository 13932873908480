<template>
  <div class="table-tile">
    <div class="table-tile__item">
      <div class="table-tile__item-block block-code">
        <span class="label">{{ $t('listItem.rma.code') }}: </span>
        <span class="value">#{{ item.id }}</span>
      </div>
      <div class="table-tile__item-block block-order-code">
        <ul class="rma-orders nav">
          <li>
            <router-link class="bcm-link bcm-link-none link-order" :to="{ name: 'myOrder', params: { id: item.order_id }}">
              <span class="label">{{ $t('listItem.order') }}: </span>
              <span class="value">{{ item.order_increment }}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="table-tile__item-block block-status position-relative">
        <!-- <b-badge pill :class="[item.status_color]">{{ item.status_name }}</b-badge> -->
        <span class="label">{{ $t('rma.status') }}: </span>
        <span class="value">{{ item.status_label }}</span>
      </div>
      <div class="table-tile__item-block block-create_at">
        <span class="label">{{ $t('listItem.rma.createdAt') }}: </span>
        <span class="value">{{ item.created_at }}</span>
      </div>
      <div class="table-tile__item-block block-actions block-latest">
        <router-link :to="{ name: 'editReturns', params: { id: item.id } }" v-t="'listItem.rma.viewReturn'" class="bcm-link bcm-link-classic"/>
      </div>
    </div>
  </div>
</template>

<script>
import ImgProduct from 'theme/components/Product/Image'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ImgProduct
  }
}
</script>
